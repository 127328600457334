<script>
import { isNil, cloneDeep, sortBy } from "lodash";
import EntityMixin from "../entity/EntityMixin.vue";

export default {
  name: "bo-gen-crud-mixin",
  mixins: [EntityMixin],
  props: {
    create: Boolean
  },
  data() {
    return {
      editableEntity: {},
      createRedirectRouteName: undefined
    };
  },
  computed: {
    storedEntity() {
      return this.transformEntity();
    },
    storedEntityString() {
      return this.storedEntity
        ? this.createComparisonStringFromObject(this.storedEntity)
        : null;
    },
    editableEntityString() {
      return this.createComparisonStringFromObject(this.editableEntity);
    },
    isChanged() {
      return this.storedEntityString !== this.editableEntityString;
    }
  },
  watch: {
    id: function() {
      this.buildEditableEntity();
    },
    isLoading: function(newValue) {
      if (!newValue) {
        this.buildEditableEntity();
      }
    }
  },
  created() {
    this.buildEditableEntity();
  },
  methods: {
    buildEditableEntity() {
      this.errors.clear();
      this.editableEntity = Object.assign(
        this.editableEntity,
        this.transformEntity() || {}
      );
    },
    transformEntity() {
      return cloneDeep(
        this.$store.getters["entities/getEntity"]({
          name: this.entityName,
          id: this.id
        })
      );
    },
    async onValidateSuccess() {
      const entityUrlFragment = `${this.entityName
        .charAt(0)
        .toUpperCase()}${this.entityName.slice(1)}`;
      if (isNil(this.id)) {
        await this.$store.dispatch(`entities/store${entityUrlFragment}`, {
          entity: this.editableEntity,
          includes: this.includes,
          cb: this.onStoreSuccess
        });
      } else {
        await this.$store.dispatch(`entities/update${entityUrlFragment}`, {
          entity: this.editableEntity,
          includes: this.includes
        });
      }
    },
    async onStoreSuccess(id) {
      this.redirectToShow(id);
      this.dialog = false;
    },
    redirectToOverview() {
      this.$router.push({ name: this.deleteRedirectRouteName });
    },
    redirectToShow(id) {
      this.$router.push({
        name: this.createRedirectRouteName,
        params: { id: id }
      });
    },
    createComparisonStringFromObject(obj) {
      return JSON.stringify(this.createComparisonObject(obj));
    },
    createComparisonObject(obj) {
      const comparisonObject = {};
      if (obj) {
        const sortedObjectKeys = sortBy(Object.keys(obj));
        for (let i = 0; i < sortedObjectKeys.length; i++) {
          const property = sortedObjectKeys[i];
          comparisonObject[property] = obj[property];
        }
      }
      return comparisonObject;
    }
  }
};
</script>
